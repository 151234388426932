import { Breadcrumbs, Button, Card, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Layout } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import FormikForm from '../../components/FormikForm/FormikForm';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import { Axios, LEADSFOLLOWUP_URL } from '../../utils/apiUtils';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import FormikMultiTextField from '../../components/FormikMultiTextField/FormikMultiTextField';
import dayjs from 'dayjs';
import FormikDateField from '../../components/FormikDateField/FormikDateField';

function LeadsFollowCreatePage() {
  const { id } = useParams();
  const authState = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(dayjs().format('YYYY-MM-DD'));

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);


  const Validator = Yup.object().shape({
    description: Yup.string()
      .matches(Regx.textWithSpclChar, "Invalid Description.")
  });

  const handleSubmit = async (data) => {
    await callCreateAPI(data);
  }

  const callCreateAPI = async (inputPayload) => {
    Axios.post(`${LEADSFOLLOWUP_URL}/${id}/create`, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.LeadsFollowPage.path+'/'+id);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.LeadsFollowPage.path+'/'+id)}}>
            Leads Follow Up
          </Link>
          <Typography color="text.primary">Create Leads FollowUp</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          <div className="aj-card-heading back-primary">
            <h4>Create Leads FollowUp</h4>
          </div>
          <div className="aj-card-body">
            <FormikForm validator={Validator} initialValues={{'telle_id': authState.user.id, 'lead_id': id, 'status':'Active'}} onSubmit={handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormikMultiTextField 
                      label="Description"
                      name="description"
                      className="aj-text-input"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormikDateField
                      label="Follow Up Date"
                      name="follow_date"
                      className="aj-text-input"
                      inputFormat="YYYY-MM-DD"
                      value={value}
                      setValue={setValue}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
                  <Button variant="contained" color="primary" className="aj-btn-bold" onClick={() => {navigate(SiteMap.LeadsFollowPage.path+'/'+id)}}>
                    Back
                  </Button>
                  <Button variant="contained" type="submit" className='aj-btn-primary' >
                    Create
                  </Button>
                </Stack>
              </Box>
            </FormikForm>
          </div>
        </Card>
      </div>
    </Layout>
  )
}

export default LeadsFollowCreatePage