import { Box, Button, Card, Divider, FormControl, Grid, MenuItem, Modal, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { Axios, HOME_URL, LEADSAMOUNT_URL, LEADSFOLLOWUP_URL, LEADSSTATUS_URL, SERVICESLIST_URL } from '../../utils/apiUtils';
import { actions } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FollowStatus, LeadsStatus } from '../../utils/constants';


function Dashboard() {
  const authState = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [lists, setLists] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [services, setServices] = useState([]);
  const [amount, setAmount] = useState(0);
  const [leadID, setLeadID] = useState(0);
  const [open, setOpen] = React.useState(false);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callHomeApi();
    callServicesListAPI();
  }, []);

  const callServicesListAPI = async (show_page) => {
    Axios.post(SERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setServices(response.data.services);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callHomeApi = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(HOME_URL, {user_id: authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data);
        setIsSet(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleChangeStatus = (event, id) => {
    Axios.post(`${LEADSSTATUS_URL}/${id}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        callHomeApi();
        if(event.target.value === 'Completed'){
          setOpen(true);
          setAmount(0);
          setLeadID(id);
        }
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };

  const handleChangeFStatus = (event, id, fid) => {
    Axios.post(`${LEADSFOLLOWUP_URL}/${id}/status/${fid}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };

  const callAmountUpdateAPI = (amount, id) => {
    Axios.post(`${LEADSAMOUNT_URL}/${id}`, {amount: amount}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        callHomeApi();
        setOpen(false);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };
  
  const handleAmount = () => {
    callAmountUpdateAPI(amount, leadID);
  }

  return (
    <Layout >
      <div className='aj-main'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Todays Target</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? Math.round(lists.dailyTarget)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Monthly Target</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.monthlyTarget)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Month Income</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsMonthIncome)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Month Expense</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsMonthExpense)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Todays Income</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsTodayIncome)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Todays Expense</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsTodayExpense)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-success b'>Total Income</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsIncome)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-danger b'>Total Expense</p>
                <p className='aj-content text-muted b'>
                  ₹{ isSet === true ? Math.round(lists.leadsExpense)  : '0' }
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Total Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.totalLeads  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>New Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.new  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Pending Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.pending  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Following Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.following  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Not Responding Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.notresponding  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Cancelled Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.cancelled  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Completed Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.completed  : '0' }
                  </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3} >
              <div className="aj-dash-card">
                <p className='aj-heading text-primary b'>Deleted Leads</p>
                <p className='aj-content text-muted b'>
                  { isSet === true ? lists.deleted  : '0' }
                  </p>
              </div>
            </Grid>
          </Grid>
        </Box>
        <br/>
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Name</TableCell>
                  <TableCell className='text-center'>Number</TableCell>
                  <TableCell className='text-center'>Company</TableCell>
                  <TableCell className='text-center'>Services</TableCell>
                  <TableCell className='text-center'>Amount</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>FollowUp Description</TableCell>
                  <TableCell className='text-center'>FollowUp Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { isSet === true && lists.leadsFollowUp.length > 0 ? 
                  lists.leadsFollowUp.map( (hr) => {
                    let serviceList = '';
                    let array = hr.services.split(",");

                    return(
                      <TableRow key={hr.id} >
                        <TableCell align="center">{hr.id}</TableCell>
                        <TableCell className='td-manage-md'>{hr.name}</TableCell>
                        <TableCell className='td-manage-md'>{hr.mobile}</TableCell>
                        <TableCell className='td-manage-md'>{hr.company}</TableCell><TableCell>{
                          hr.services && services && services.map(service => {
                            if(array.includes(service.id.toString())) {
                              if(serviceList === '')
                                serviceList = service.label;
                              else 
                                serviceList =  ', ' + service.label;
                              return serviceList;
                            }
                          })
                        }</TableCell>
                        <TableCell>{hr.amount}</TableCell>
                        <TableCell >
                          {hr.status === 'Completed' 
                          ? 
                            <span className='text-success b'>Completed</span>
                          : 
                            <FormControl fullWidth size="small">
                              <Select
                                name={hr.id ? `status-${hr.id}` : ''}
                                defaultValue={hr.status}
                                onChange={(e)=>{handleChangeStatus(e, hr.id)}}
                              >
                                { LeadsStatus &&  LeadsStatus.map(option => (
                                  <MenuItem id={option.value} key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }
                        </TableCell>
                        
                        <TableCell className='td-manage-md'>{hr.description}</TableCell>
                        <TableCell >
                          {hr.followstatus === 'Completed' 
                          ? 
                            <span className='text-success b'>Completed</span>
                          : 
                            <FormControl fullWidth size="small">
                              <Select
                                name={hr.id ? `followstatus-${hr.id}` : ''}
                                defaultValue={hr.followstatus}
                                onChange={(e)=>{handleChangeFStatus(e, hr.id, hr.fid)}}
                              >
                                { FollowStatus &&  FollowStatus.map(option => (
                                  <MenuItem id={option.value} key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  }) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={9} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="aj-model-box">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Leads Amount
            </Typography>
            <Divider />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                label="Lead Amount"
                name="amount"
                id="amount"
                className="aj-text-input"
                size="small"
                fullWidth
                onChange = { (e) => {setAmount(e.target.value)} }
              />
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="end" alignItems="center">
              <Button variant="contained" color="success" className="aj-btn-bold" size="small" onClick={ () => handleAmount()}>
                Update Amount
              </Button>
            </Stack>
          </Box>
        </Modal>

      </div>
    </Layout>
  )
}

export default Dashboard