import { Breadcrumbs, Button, Card, Divider, Fab, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Add as AddIcon } from '@mui/icons-material';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Axios, LEADSAMOUNT_URL, LEADSCOMMENTSCREATE_URL, LEADSLIST_URL, LEADSSTATUS_URL, SERVICESLIST_URL } from '../../utils/apiUtils';
import moment from 'moment';
import { Box } from '@mui/system';
import { ALLLimits, LeadsStatus } from '../../utils/constants';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

import EditIcon from '@mui/icons-material/Edit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


const LeadsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const [lists, setLists] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('All');
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [openComment, setOpenComment] = React.useState(false);
  const [services, setServices] = useState([]);
  const [amount, setAmount] = useState(0);
  const [leadID, setLeadID] = useState(0);
  const [hrID, setHRID] = useState(0);
  const [tcID, setTCID] = useState(0);
  const [comments, setComments] = useState('');
  const [changestatus, setChangeStatus] = useState('');
  const [todayDate, setTodayDate] = useState(null);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callLeadsListAPI();
    callServicesListAPI();
  }, []);

  const handleDateChange = (newValue) => {
    setTodayDate(dayjs(newValue).format('YYYY-MM-DD'));
  };

  const callServicesListAPI = async (show_page) => {
    Axios.post(SERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setServices(response.data.services);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callLeadsListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(LEADSLIST_URL, {page: show_page, search: search, status: status, limit: limit, tc_id: authState.user.id, tdate: todayDate}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.users);
        setCount( Math.ceil(response.data.count / limit));
        setPage(response.data.page);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handlePageChange = (event, newPage)=>{
    callLeadsListAPI(newPage);
  }

  const handleChangeStatus = (event, id, tcId, hrId) => {
    Axios.post(`${LEADSSTATUS_URL}/${id}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        callLeadsListAPI();
        if(event.target.value === 'Completed'){
          setOpen(true);
          setAmount(0);
        }
        setLeadID(id);
        setTCID(tcId);
        setHRID(hrId);
        setChangeStatus(event.target.value);
        setOpenComment(true);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };

  const callAmountUpdateAPI = (amount, id) => {
    Axios.post(`${LEADSAMOUNT_URL}/${id}`, {amount: amount}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        callLeadsListAPI();
        setOpen(false);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };
  
  const handleAmount = () => {
    callAmountUpdateAPI(amount, leadID);
  }

  const callCommentUpdateAPI = (comments, id, tc_id, hr_id, status) => {
    Axios.post(`${LEADSCOMMENTSCREATE_URL}/${id}`, {comments: comments, tc_id: tc_id, hr_id: hr_id, status: status}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        callLeadsListAPI();
        setOpenComment(false);
        setComments('');
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleComment = () => {
    callCommentUpdateAPI(comments, leadID, tcID, hrID, changestatus);
  }

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">Leads</Typography>
        </Breadcrumbs>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                size="small"
                label="Search"
                name="search"
                id="hr-search"
                className="aj-text-input"
                fullWidth
                onChange={ (e) => {setSearch(e.target.value)} }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <Stack spacing={2}>
                  <DesktopDatePicker
                    label="From Date"
                    name="from_date"
                    className="aj-text-input"
                    value={todayDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField size="small" {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="hr-status-label" >Status</InputLabel>
                <Select
                  size="small"
                  labelId="hr-status-label"
                  id="hr-status"
                  label="Status"
                  name="status"
                  defaultValue="All"
                  onChange={ (e) => {setStatus(e.target.value)} }
                >
                  <MenuItem id="all" key="all" value="All">Choose Status</MenuItem>
                  { LeadsStatus && LeadsStatus.map(option => (
                    <MenuItem id={option.value} key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="hr-limit-label" >Limit</InputLabel>
                <Select
                  size="small"
                  labelId="hr-limit-label"
                  id="hr-limit"
                  label="Limit"
                  name="limit"
                  defaultValue={10}
                  onChange={ (e) => {setLimit(e.target.value)} }
                >
                  {ALLLimits &&
                    ALLLimits.map(option => (
                      <MenuItem id={option.value} key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                variant="contained"
                type="submit"
                className='aj-btn-primary'
                id="hr-submit"
                onClick={ () => {callLeadsListAPI()} }
                fullWidth
                >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
        <br />
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Basic Details</TableCell>
                  <TableCell className='text-center'>Numbers</TableCell>
                  <TableCell className='text-center'>Services</TableCell>
                  <TableCell className='text-center'>Comments</TableCell>
                  <TableCell className='text-center'>Other Details</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  lists.map( (hr) => {
                    let serviceList = '';
                    let array = hr.services.split(",");

                    return(
                      <TableRow key={hr.id} >
                        <TableCell align="center">{hr.id}</TableCell>
                        <TableCell>
                          <div>
                            <span className='block'>Name:<b>{hr.name}</b></span>
                            <span className='block'>Company:<b>{hr.company}</b></span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <span className='block'>Mobile:<b>{hr.mobile}</b></span>
                            <span className='block'>Whatsapp:<b>{hr.whatsapp}</b></span>
                          </div>
                        </TableCell>
                        <TableCell>{
                          hr.services && services && services.map(service => {
                            if(array.includes(service.id.toString())) {
                              if(serviceList === '')
                                serviceList = service.label;
                              else 
                                serviceList =  ', ' + service.label;
                              return serviceList;
                            }
                          })
                        }</TableCell>
                        <TableCell>{hr.comments}</TableCell>
                        <TableCell>
                          <div>
                            <span className='block'>Amount:<b>{hr.amount}</b></span>
                            <span className='block'>Created At:<b>{ hr.created_at ? moment(hr.created_at).format("YYYY-MM-DD kk:mm:00") : '-' }</b></span>
                          </div>
                        </TableCell>
                        <TableCell >
                          {hr.status === 'Completed' 
                          ? 
                            <span className='text-success b'>Completed</span>
                          : 
                            <FormControl fullWidth size="small">
                              <Select
                                name={hr.id ? `status-${hr.id}` : ''}
                                defaultValue={hr.status}
                                onChange={(e)=>{handleChangeStatus(e, hr.id, hr.telle_id, hr.hr_id)}}
                              >
                                { LeadsStatus &&  LeadsStatus.map(option => (
                                  <MenuItem id={option.value} key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }
                        </TableCell>
                        <TableCell align="center">
                          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Button variant="contained" color="warning" startIcon={<EditIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.LeadsPage.path+'/'+hr.id)}}>
                              Edit
                            </Button>
                            {hr.status !== 'New' && hr.status !== 'Pending' && hr.status !== 'Completed'
                            ? 
                              <Button variant="contained" color="info" startIcon={<TaskAltIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.LeadsFollowPage.path+'/'+hr.id)}}>
                                FollowUp
                              </Button>
                            : '' }
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={9} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
              {count > 0 ?
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Pagination count={count} page={page} onChange={handlePageChange} color="primary" shape="rounded" />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              : '' }
            </Table>
          </TableContainer>
        </Card>
      </div>
        
      <Fab color="secondary" aria-label="add" className='aj-add-icon'  onClick={() => {navigate(SiteMap.LeadsCreatePage.path)}}>
        <AddIcon />
      </Fab>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="aj-model-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Leads Amount
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              label="Lead Amount"
              name="amount"
              id="amount"
              className="aj-text-input"
              size="small"
              fullWidth
              onChange = { (e) => {setAmount(e.target.value)} }
            />
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="end" alignItems="center">
            <Button variant="contained" color="success" className="aj-btn-bold" size="small" onClick={ () => handleAmount()}>
              Update Amount
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openComment}
        onClose={() => setOpenComment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="aj-model-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Status Change Comments
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" sx={{ mt: 2, width: '60%', minWidth:260}} >
            <TextField
              label="Status Change Comments"
              name="comments"
              id="comments"
              className="aj-text-input"
              size="small"
              fullWidth
              onChange = { (e) => {setComments(e.target.value)} }
              required
            />
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="end" alignItems="center">
            <Button variant="contained" color="success" className="aj-btn-bold" size="small" onClick={ () => handleComment()}>
              Update Comments
            </Button>
          </Stack>
        </Box>
      </Modal>

    </Layout>
  )
}

export default LeadsPage