import React, { useEffect } from "react";
import { Field } from "formik";
import {
	Select,
	MenuItem,
	FormHelperText,
	FormControl,
	InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useState } from "react";

const FormikMultiSelect = props => {
	const { name, label, className, items} =	props;
  
  
	const Content = ({ form, meta }) => {
    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = (event) => {
      let values = event.target.value;
			// console.log({values});
			let idList = values.map(item => item['id'])
      setSelectedValues(idList);
      form.setFieldValue(name, idList);
    };

		useEffect(() => {
			if(meta.initialValue)
			{
				// console.log("in");
				setSelectedValues(meta.initialValue);
			}
		}, [])

		// console.log({selectedValues});


		return (
			<React.Fragment>
				<FormControl fullWidth error={Boolean(meta.error)}>
					{label && <InputLabel id={`label-${name}`} >{label}</InputLabel>}
					<Select
            labelId={`label-${name}`}
            label={label}
            size='small'
            fullWidth
						className={className}
						id={name}
						name={name}
						multiple
            value={
							selectedValues?(items.filter(o => selectedValues.includes(o.id))): []
						}
						onChange={e => {
              handleChange(e);
						}}
						error={
							(meta.touched || form.submitCount > 0) && Boolean(meta.error)
						}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.map(a => a.label).join(', ')}
					>
            {items &&
              items.map(option => (
                <MenuItem id={option.id} key={option.id} value={option}>
                  <Checkbox id={'checkbox-'+option.id} checked={selectedValues.includes(option.id)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
            ))}
					</Select>
					{(meta.touched || form.submitCount > 0) && meta.error && (
						<FormHelperText>{meta.error}</FormHelperText>
					)}
				</FormControl>
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default FormikMultiSelect;
