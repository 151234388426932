import React from 'react'
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Logout as LogoutIcon, AccountCircle as AccountCircleIcon, Person3, Home, Call  } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { actions } from '../../redux'
import { SiteMap } from '../../Routes';
import { useState } from 'react';
import Footer from '../Footer/Footer';

import logoIcon from '../../assets/images/logo.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ArticleIcon from '@mui/icons-material/Article';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Layout = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const isTab = useMediaQuery({ query:'(max-width:900px)' })
	const authState = useSelector(state => state.auth);
  const { user, token_expires_at } = authState;

  React.useEffect(() => {
    if (isTab) setOpen(false);
  }, [isTab]);

  const { RegisterLogout } = bindActionCreators(
    actions.auth, 
    dispatch
  );

  const [ currentActive, setCurrentActive ] = useState(window.location.pathname);

	const [ currentDate, setCurrentDate] = useState(new Date());
  React.useEffect( () => {
    if (new Date(token_expires_at) < currentDate) {
      RegisterLogout();
    }
  }, [currentDate]);

  

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open} className="aj-header">
        <Toolbar>
          {open ?
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={() => { setOpen(false) }}
              edge="start"
              className="aj-header-button-one"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
          :
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => { setOpen(true) }}
            edge="start"
            className="aj-header-button-one"
            sx={{
              marginRight: 5,
            }}
          >
            <CloseIcon />
          </IconButton>
          }
          <div className="aj-header-items">
            <Button varient="text" startIcon={<AccountCircleIcon />} className="aj-header-button-one"> { user?.name } </Button>
            <Button varient="text" startIcon={<LogoutIcon />} className="aj-header-button-one" onClick={() => { RegisterLogout() }} >Logout</Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <div className="aj-siderbar-logo" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
          <img src={logoIcon} alt="Seclob Icon" />
          <h1>Seclob</h1>
        </div>
        <Divider />
        <List className='aj-sidebar-lists'>
          <ListItem disablePadding onClick={() => {navigate(SiteMap.DashboardPage.path)}} className={currentActive === SiteMap.DashboardPage.path ? "aj-siderbar-list active" : "aj-siderbar-list" }>
            <ListItemButton className={open ? '' : 'aj-list-small'}>
              <ListItemIcon className='aj-sidebar-icons'>
                <Home />
              </ListItemIcon>
              {open ? <ListItemText primary="Dashboard" /> : ''}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={() => {navigate(SiteMap.LeadsPage.path)}} className={ (currentActive.split("/")[1] === SiteMap.LeadsPage.path.split('/')[1]) ? "aj-siderbar-list active" : "aj-siderbar-list"  }>
            <ListItemButton>
              <ListItemIcon className='aj-sidebar-icons'>
                <Person3 />
              </ListItemIcon>
              {open ? <ListItemText primary="Leads" /> : ''}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={() => {navigate(SiteMap.NotificationPage.path)}} className={ (currentActive.split("/")[1] === SiteMap.NotificationPage.path.split('/')[1]) ? "aj-siderbar-list active" : "aj-siderbar-list"  }>
            <ListItemButton>
              <ListItemIcon className='aj-sidebar-icons'>
                <NotificationsIcon />
              </ListItemIcon>
              {open ? <ListItemText primary="Notifications" /> : ''}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={() => {navigate(SiteMap.LeavesPage.path)}} className={ (currentActive.split("/")[1] === SiteMap.LeavesPage.path.split('/')[1]) ? "aj-siderbar-list active" : "aj-siderbar-list"  }>
            <ListItemButton>
              <ListItemIcon className='aj-sidebar-icons'>
                <MarkunreadIcon />
              </ListItemIcon>
              {open ? <ListItemText primary="Leave Application" /> : ''}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={() => {navigate(SiteMap.WorkSpacePage.path)}} className={ (currentActive.split("/")[1] === SiteMap.WorkSpacePage.path.split('/')[1]) ? "aj-siderbar-list active" : "aj-siderbar-list"  }>
            <ListItemButton>
              <ListItemIcon className='aj-sidebar-icons'>
                <ArticleIcon />
              </ListItemIcon>
              {open ? <ListItemText primary="Work Report" /> : ''}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={() => {navigate(SiteMap.ExpensePage.path)}} className={ (currentActive.split("/")[1] === SiteMap.ExpensePage.path.split('/')[1]) ? "aj-siderbar-list active" : "aj-siderbar-list"  }>
            <ListItemButton>
              <ListItemIcon className='aj-sidebar-icons'>
                <CurrencyExchangeIcon />
              </ListItemIcon>
              {open ? <ListItemText primary="Expences" /> : ''}
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {props.children}
        <Footer />
      </Box>
    </Box>
  )
}

export default Layout








