import { Box, Breadcrumbs, Button, Card, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormikSelect, FormikTextField, Layout } from '../../components';
import FormikForm from '../../components/FormikForm/FormikForm';
import { actions } from '../../redux';
import { Axios, DISTRICTSEARCH_URL, LEADSLIST_URL, LEADSUPDATE_URL, SERVICESLIST_URL, STATESEARCH_URL } from '../../utils/apiUtils';
import { SiteMap } from '../../Routes';
import * as Yup from "yup";
import { Regx } from '../../utils/Regx';
import FormikMultiTextField from '../../components/FormikMultiTextField/FormikMultiTextField';
import FormikAutoComplete from '../../components/FormikAutoComplete/FormikAutoComplete';
import FormikMultiSelect from '../../components/FormikMultiSelect/FormikMultiSelect';
import { LeadsStatus } from '../../utils/constants';

function LeadsUpdatePage() {
  const { id } = useParams();
  const authState = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hrData, setHRData] = useState({});
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [state , setState] = useState([]);
  const [search, setSearch] = useState('');
  const [district , setDistrict] = useState([]);
  const [searchDistrict, setSearchDistrict] = useState('');
  const [stateID , setStateID] = useState(0);
  const [distReset , setDistReset] = useState(0);

  

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const Validator = Yup.object().shape({
    name: Yup.string()
      .required("User Name Required.")
      .max(60, "User Name exceeds Character limit.")
      .min(6, "User Name too Short.")
      .matches(Regx.plainText, "Invalid User Name."),
    mobile: Yup.string()
      .required("Mobile Number Required.")
      .min(10, '10 digit Mobile Number Required.')
      .max(10, '10 digit Mobile Number Required.'),
    whatsapp: Yup.string()
      .min(10, '10 digit Mobile Number Required.')
      .max(10, '10 digit Mobile Number Required.'),
    company: Yup.string()
      .max(60, "Company Name exceeds Character limit.")
      .min(6, "Company Name too Short.")
      .matches(Regx.plainText, "Invalid Company Name."),
    telle_desc: Yup.string()
      .matches(Regx.textWithSpclChar, "Invalid Description."),
    services: Yup.array()
      .min(1, "Atleast one service required"),
    state_id: Yup.string()
      .matches(Regx.number, "Invalid State."),
    district_id: Yup.string()
      .matches(Regx.number, "Invalid District."),
    address: Yup.string()
      .matches(Regx.textWithSpclChar, "Invalid Address.")
  });

  React.useEffect(() => {
    if (Object.keys(hrData).length === 0) callSingleAPI(id);

    callServicesListAPI();
    callStateListAPI(search);
    callDistrictListAPI(searchDistrict);
  }, [search, searchDistrict]);

  const callServicesListAPI = async (show_page) => {
    Axios.post(SERVICESLIST_URL, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setServices(response.data.services);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callStateListAPI =  (search) => {
    Axios.post(STATESEARCH_URL, {search:search}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setState(response.data.states);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callDistrictListAPI =  (search, stateID) => {
    Axios.post(DISTRICTSEARCH_URL, {search:search, state_id: stateID}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setDistReset(distReset + 1);
        setDistrict(response.data.districts);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const callSingleAPI = async (id) => {
    await Axios.post(`${LEADSLIST_URL}/${id}`, {}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        setHRData({...response.data.users[0]});
        const dataSer =({...response.data.users[0]});
        dataSer['services'] =  dataSer['services'].split(',').map(Number);
        setHRData({...dataSer});
        setStateID(dataSer['state_id']);
        setLoading(false);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleSubmit = async (data) => {
    await callHRCreateAPI(data);
  }

  const callHRCreateAPI = async (inputPayload) => {
    Axios.post(`${LEADSUPDATE_URL}/${id}`, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
        navigate(SiteMap.LeadsPage.path);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  React.useEffect(() => {
    callDistrictListAPI(null, stateID)
  }, [stateID])

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.LeadsPage.path)}}>
            Leads
          </Link>
          <Typography color="text.primary">Update Lead</Typography>
        </Breadcrumbs>

        <Card sx={{ minWidth: 180}} className="aj-card-main">
          {
            loading? <>Loading...</> 
            :
            <React.Fragment>
              <div className="aj-card-heading back-primary">
                <h4>Update Member: {hrData.name ? hrData.name : ''}</h4>
              </div>
              <div className="aj-card-body">
                <FormikForm 
                  initialValues={hrData} 
                  validator={Validator}  
                  onSubmit={handleSubmit}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 0.5, sm:0.5, md: 1, lg: 1, xl:2 }}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Lead Name"
                          name="name"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Mobile Number"
                          name="mobile"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Whatsapp/Second Number"
                          name="whatsapp"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikTextField 
                          label="Company Name"
                          name="company"
                          className="aj-text-input"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikMultiSelect
                          label="Services"
                          size="small"
                          name="services"
                          items={services}
                          placeholder="Choose Services"
                          className="aj-text-input"
                          />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikAutoComplete
                          name="state_id"
                          size="small"
                          label="Select State"
                          className="aj-text-input"
                          items={state}
                          setSearch={() => {setSearch()}}
                          onChange={(e, newValue) => { if(newValue) setStateID(newValue.id); }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikAutoComplete
                          name="district_id"
                          size="small"
                          label="Select District"
                          className="aj-text-input"
                          items={district}
                          setSearch={() => {setSearchDistrict()}}
                          reset={() => {setDistReset()}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <FormikSelect
                          label="Status"
                          size="small"
                          name="status"
                          items={LeadsStatus}
                          className="aj-text-input"
                          // defaultValue='Active'
                          placeholder="Choose Status"
                          />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormikMultiTextField
                          label="TelleCaller Description"
                          name="telle_desc"
                          className="aj-text-input"
                          size="small"
                          rows="4"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormikMultiTextField
                          label="TelleCaller Comments"
                          name="comments"
                          className="aj-text-input"
                          size="small"
                          rows="4"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormikMultiTextField
                          label="Address"
                          name="address"
                          className="aj-text-input"
                          size="small"
                          rows="4"
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className='aj-card-footer'>
                      <Button variant="contained" color="primary" className="aj-btn-bold"  onClick={() => {navigate(SiteMap.LeadsPage.path)}}>
                        Back
                      </Button>
                      <Button variant="contained" type="submit" className='aj-btn-primary' >
                        Update
                      </Button>
                    </Stack>
                  </Box>
                </FormikForm>
              </div>
            </React.Fragment>
          }
        </Card>
      </div>
    </Layout>
  )
}

export default LeadsUpdatePage