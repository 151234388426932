export const Regx = {
  mobile: /^\d{10}$/,
  email: /^[a-zA-Z0-9_.]+@[a-zA-Z_.]+?\.[a-zA-Z]{2,6}$/,
  pin: /^\d{6}$/,
  plainText: /^[a-zA-Z0-9 ]+$/,
  alphaNumeral: /^[a-zA-Z0-9]+$/,
  plaintTextWithoutDigits: /^[a-zA-Z]+$/,
  textWithSpclChar: /^[a-zA-Z0-9_&.,\-$\n ]+$/,
  number: /^\d+$/,
  emailOrPhone: /^(?:\d{10}|[a-zA-Z0-9_.]+@[a-zA-Z_.]+?\.[a-zA-Z]{2,6})$/
}