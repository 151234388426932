import axios from 'axios';
import { store } from "../redux/store";

function getToken() {
    let token = store.getState().auth.token.access_token;
    return token;
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

export const LOGIN_URL = '/telecaller/login';
export const HOME_URL = '/telecaller/home';
export const ADMINLOGIN_URL = '/telecaller/admin/login';

export const LEADSCREATE_URL = '/telecaller/leads/create';
export const LEADSLIST_URL = '/telecaller/leads';
export const LEADSUPDATE_URL = '/telecaller/leads/update';
export const LEADSDELETE_URL = '/telecaller/leads/delete';
export const LEADSSTATUS_URL = '/telecaller/leads/status';
export const LEADSAMOUNT_URL = '/telecaller/leads/amount';
export const LEADSFOLLOWUP_URL = '/telecaller/leads/followup';


export const LEADSCOMMENTSCREATE_URL = '/telecaller/leads/comments/create';


export const SERVICESLIST_URL = '/home/services/search';

export const STATESEARCH_URL = '/location/state/search';
export const DISTRICTSEARCH_URL = '/location/district/search';

export const NOTIFICATIONLIST_URL = '/notifications';
export const NOTIFICATIONSTATUS_URL = '/notifications/status';
export const NOTIFICATIONPRIORITY_URL = '/notifications/priority';

export const LEAVECREATE_URL = '/leaves/create';
export const LEAVELIST_URL = '/leaves';
export const LEAVEUPDATE_URL = '/leaves/update';
export const LEAVEDELETE_URL = '/leaves/delete';

export const WORKSPACECREATE_URL = '/workspace/create';
export const WORKSPACELIST_URL = '/workspace';
export const WORKSPACEUPDATE_URL = '/workspace/update';

export const EXPENSECREATE_URL = '/expense/create';
export const EXPENSELIST_URL = '/expense';
export const EXPENSEUPDATE_URL = '/expense/update';

export const Axios = axios;
