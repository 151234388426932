import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import DashboardPage from "./pages/home/DashboardPage";
import { useSelector } from "react-redux";
import { Error404 } from "./components";
import LeadsPage from "./pages/leads/LeadsPage";
import LeadsCreatePage from "./pages/leads/LeadsCreatePage";
import LeadsUpdatePage from "./pages/leads/LeadsUpdatePage";
import NotificationPage from "./pages/notifications/NotificationPage";
import LeavesPage from "./pages/leaves/LeavesPage";
import LeavesCreatePage from "./pages/leaves/LeavesCreatePage";
import LeavesUpdatePage from "./pages/leaves/LeavesUpdatePage";
import WorkSpacePage from "./pages/workspace/WorkSpacePage";
import WorkSpaceCreatePage from "./pages/workspace/WorkSpaceCreatePage";
import WorkSpaceUpdatePage from "./pages/workspace/WorkSpaceUpdatePage";
import ExpensePage from "./pages/expense/ExpensePage";
import ExpenseCreatePage from "./pages/expense/ExpenseCreatePage";
import ExpenseUpdatePage from "./pages/expense/ExpenseUpdatePage";
import LeadsFollowPage from "./pages/leads/LeadsFollowPage";
import LeadsFollowCreatePage from "./pages/leads/LeadsFollowCreatePage";
import LeadsFollowUpdatePage from "./pages/leads/LeadsFollowUpdatePage";
import AdminLoginPage from "./pages/login/AdminLoginPage";



export const SiteMap = {
	HomePage: { path: "/" },
  DashboardPage: { path: "/dashboard" },
	AdminLoginPage: { path: "/admin/:id" },
	NotificationPage: { path: "/notifications" },

	LeadsPage: { path: "/leads" },
	LeadsCreatePage: { path: "/leads/create" },
	LeadsUpdatePage: { path: "/leads/:id" },
	LeadsFollowPage: { path: "/leads/followup" },
	LeadsFollowListPage: { path: "/leads/followup/:id" },
	LeadsFollowCreatePage: { path: "/leads/followup/:id/create" },
	LeadsFollowUpdatePage: { path: "/leads/followup/:id/update/:fid" },

	LeavesPage: { path: "/leaves" },
	LeavesCreatePage: { path: "/leaves/create" },
	LeavesUpdatePage: { path: "/leaves/:id" },

	WorkSpacePage: { path: "/workspace" },
	WorkSpaceCreatePage: { path: "/workspace/create" },
	WorkSpaceUpdatePage: { path: "/workspace/:id" },

	ExpensePage: { path: "/expense" },
	ExpenseCreatePage: { path: "/expense/create" },
	ExpenseUpdatePage: { path: "/expense/:id" },
	
}

const PrivateRoutesWrapper = ({ isUser }) => {
	return isUser ? <Outlet /> : <Navigate to={SiteMap.HomePage.path} />;
}

const PublicRoutesWrapper = ({ isUser }) => {
	return !isUser ? <Outlet /> : <Navigate to={SiteMap.DashboardPage.path} />;
}

const RouteItems = () => {
	const authState = useSelector(state => state.auth);
  const { isSessionValid } = authState;

  return (
    <Routes>
			<Route path={SiteMap.AdminLoginPage.path} element={<AdminLoginPage />} />
      {/* Public Routes */}
			<Route path="/" element={<PublicRoutesWrapper isUser={isSessionValid} />}>
				<Route path={SiteMap.HomePage.path} element={<LoginPage />} />
			</Route>

      {/* Private Routes */}
			<Route path="/" element={<PrivateRoutesWrapper isUser={isSessionValid} />}>
				<Route path={SiteMap.DashboardPage.path} element={<DashboardPage />} />
				<Route path={SiteMap.NotificationPage.path} element={<NotificationPage />} />
				
				<Route path={SiteMap.LeadsPage.path} element={<LeadsPage />} />
				<Route path={SiteMap.LeadsCreatePage.path} element={<LeadsCreatePage />} />
				<Route path={SiteMap.LeadsUpdatePage.path} element={<LeadsUpdatePage />} />
				<Route path={SiteMap.LeadsFollowListPage.path} element={<LeadsFollowPage />} />
				<Route path={SiteMap.LeadsFollowCreatePage.path} element={<LeadsFollowCreatePage />} />
				<Route path={SiteMap.LeadsFollowUpdatePage.path} element={<LeadsFollowUpdatePage />} />

				<Route path={SiteMap.LeavesPage.path} element={<LeavesPage />} />
				<Route path={SiteMap.LeavesCreatePage.path} element={<LeavesCreatePage />} />
				<Route path={SiteMap.LeavesUpdatePage.path} element={<LeavesUpdatePage />} />

				<Route path={SiteMap.WorkSpacePage.path} element={<WorkSpacePage />} />
				<Route path={SiteMap.WorkSpaceCreatePage.path} element={<WorkSpaceCreatePage />} />
				<Route path={SiteMap.WorkSpaceUpdatePage.path} element={<WorkSpaceUpdatePage />} />

				<Route path={SiteMap.ExpensePage.path} element={<ExpensePage />} />
				<Route path={SiteMap.ExpenseCreatePage.path} element={<ExpenseCreatePage />} />
				<Route path={SiteMap.ExpenseUpdatePage.path} element={<ExpenseUpdatePage />} />

			</Route>

			<Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default RouteItems;