import React from 'react';
import Login from '../../components/Login/Login';
import FormikForm from '../../components/FormikForm/FormikForm';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Regx } from '../../utils/Regx';
import * as Yup from "yup";
import { Axios, LOGIN_URL } from '../../utils/apiUtils';
import { actions } from '../../redux'
import { SiteMap } from '../../Routes';
import { useNavigate } from "react-router-dom"

const LoginPage =() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector(state => state.auth);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  const { RegisterLogin } = bindActionCreators(
    actions.auth, 
    dispatch
  );

  React.useEffect(() => {
		if (authState.isSessionValid) navigate(SiteMap.DashboardPage.path);
	}, [authState.isSessionValid])

  const Validator = Yup.object().shape({
    mobile: Yup.string()
        .required("Mobile Number Required.")
        .matches(Regx.mobile, "Invalid Mobile Number."),
    password: Yup.string()
        .required("Password Required.")
        .min(6, "Password too Short.")
  })

  const callLoginAPI = async (inputPayload) => {
    Axios.post(LOGIN_URL, inputPayload).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" })
      else {
        var expiryTime = new Date();
        expiryTime.setSeconds(expiryTime.getSeconds() + response.data.expiry_in);
        let auth_data = {
          user: {
            name: response.data.name,
            hr_id: response.data.hr_id,
            id: response.data.id
          },
          token: {
            access_token: response.data.access_token,
            expiry_in: response.data.expiry_in
          },
          token_expires_at: expiryTime
        }
        RegisterLogin(auth_data);
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleSubmit = async (data) => {
    await callLoginAPI(data);
  }
  return (
    <React.Fragment>
      <FormikForm
        validator={Validator}
        onSubmit={handleSubmit}>
        <Login/>
      </FormikForm>
    </React.Fragment>
  )
}

export default LoginPage;