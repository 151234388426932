import { Button } from '@mui/material';
import React from 'react';
import logoBack from '../../assets/images/seclob_web.png';
import FormikTextField from '../FormikTextField/FormikTextField';


import './Login.css';

const Login = () => {
  return (
    <div className="">
      <div className="aj-center-card">
        <img src={logoBack} />
        <hr/>
        <h5>Sign in to Start your Sesion.</h5>
        <div className='mb-1'>
          <FormikTextField 
            name="mobile"
            type="number"
            className="aj-text-input"
            size="small"
            placeholder="Mobile Number"
            />
        </div>
        <div className='mb-1'>
          <FormikTextField 
            name="password"
            type="password"
            className="aj-text-input"
            size="small"
            placeholder="Password"
            />
        </div>
        
        <Button
          variant="contained"
          type="submit"
          className='aj-btn-primary'
          >
          Sign In
        </Button>
      </div>
    </div>
  );
}

export default Login