import { Breadcrumbs, Button, Card, Fab, FormControl, Link, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Layout } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { Add as AddIcon } from '@mui/icons-material';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Axios, LEADSFOLLOWUP_URL } from '../../utils/apiUtils';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { FollowStatus } from '../../utils/constants';

const LeadsFollowPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const [lists, setLists] = useState([]);
  const [count, setCount] = useState(0);

  const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

  React.useEffect(() => {
    callLeadsListAPI();
  }, []);

  const callLeadsListAPI = async (show_page) => {
    if( show_page === undefined || show_page === null) show_page = 1;

    Axios.post(`${LEADSFOLLOWUP_URL}/${id}`, {telle_id: authState.user.id}).then((response) => {
      if (response.data.sts === "00")
        ShowToast({ "message": response.data.msg, "severity": "error" });
      else {
        setLists(response.data.data);
        setCount(1);
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  }

  const handleChangeStatus = (event, fid) => {
    Axios.post(`${LEADSFOLLOWUP_URL}/${id}/status/${fid}`, {status: event.target.value}).then((response) => {
      if (response.data.sts === "00") {
        ShowToast({ "message": response.data.msg, "severity": "error" });
      } else {
        ShowToast({ "message": response.data.msg, "severity": "success" });
      }
    }).catch( err=> {
      console.log("err res", err.response);
      if (err.response && err.response.data && err.response.data.msg )
        ShowToast({ "message": err.response.data.msg, "severity": "error" });
      else
        ShowToast({ "message": err.message, "severity": "error" });
    });
  };

  return (
    <Layout >
      <div className='aj-main'>
        <Breadcrumbs aria-label="breadcrumb" className='aj-bread-crumbs'>
          <Link underline="hover" color="inherit" href="#" onClick={() => {navigate(SiteMap.DashboardPage.path)}}>
            Dashboard
          </Link>
          <Typography color="text.primary">Leads FollowUp Dates</Typography>
        </Breadcrumbs>
      
        <Card >
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small' className='aj-resp-table'>
              <TableHead className='aj-table-primary'>
                <TableRow>
                  <TableCell className='text-center'>ID</TableCell>
                  <TableCell className='text-center'>Date</TableCell>
                  <TableCell className='text-center'>Message</TableCell>
                  <TableCell className='text-center'>Status</TableCell>
                  <TableCell className='text-center'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { count > 0 ? 
                  lists.map( (hr) => {
                    return(
                      <TableRow key={hr.id} >
                        <TableCell align="center">{hr.id}</TableCell>
                        <TableCell>{ hr.follow_date ? moment(hr.follow_date).format("YYYY-MM-DD") : '-' }</TableCell>
                        <TableCell className='td-manage-md'>{hr.description}</TableCell>
                        <TableCell >
                          {hr.status === 'Completed' 
                          ? 
                            <span className='text-success b'>Completed</span>
                          : 
                            <FormControl fullWidth size="small">
                              <Select
                                name={hr.id ? `status-${hr.id}` : ''}
                                defaultValue={hr.status}
                                onChange={(e)=>{handleChangeStatus(e, hr.id)}}
                              >
                                { FollowStatus &&  FollowStatus.map(option => (
                                  <MenuItem id={option.value} key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          }
                        </TableCell>
                        <TableCell align="center">
                          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Button variant="contained" color="warning" startIcon={<EditIcon />} className="aj-btn-bold" size="medium" onClick={() => {navigate(SiteMap.LeadsFollowPage.path+'/'+id+'/update/'+hr.id)}}>
                              Edit
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }) 
                : 
                  <TableRow key={0} >
                    <TableCell colSpan={9} align="center">No Results Found!</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
        
      <Fab color="secondary" aria-label="add" className='aj-add-icon'  onClick={() => {navigate(SiteMap.LeadsFollowPage.path+'/'+id+'/create')}}>
        <AddIcon />
      </Fab>

    </Layout>
  )
}

export default LeadsFollowPage